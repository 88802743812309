<template>
  <div>
    <b-card class="text-center" v-if="isDownloading" body-class="p-1">
      <b-spinner small variant="primary" label="Spinning"></b-spinner>
      <span class="text-primary ml-2">Downloading...</span>
    </b-card>
    <div v-else>
      <a
        v-if="file.signedUrl"
        :href="file.signedUrl"
        target="_blank"
        @click.prevent.stop="
          downloadFile(file.signedUrl, file.originalFileName)
        "
      >
        <b-img
          v-if="isImageFile(file.originalFileName)"
          :src="file.signedUrl"
          fluid
          alt="Fluid image"
        ></b-img>
        <span v-else>
          {{ file.originalFileName }}
        </span>
      </a>
      <div v-else v-html="messageSanitized"></div>
    </div>

    <b-modal
      centered
      hide-footer
      hide-header
      hide-backdrop
      size="sm"
      body-class="text-center"
    >
      <b-spinner variant="primary" label="Spinning"></b-spinner>
      <span class="text-primary ml-3">Downloading...</span>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import HttpHandler from '@/mixins/HttpHandler';
import sanitizeHtml from 'sanitize-html';

export default {
  name: 'MessageViewer',
  props: {
    message: { type: String, default: '{}' },
  },
  mixins: [HttpHandler],
  data() {
    return {
      file: _defaultFile(),
      isDownloading: false,
    };
  },
  mounted() {
    this.loadFileUrl();
  },
  watch: {
    messageJson() {
      this.loadFileUrl();
    },
  },
  computed: {
    messageJson() {
      try {
        return JSON.parse(this.message);
      } catch (ex) {
        return {};
      }
    },
    messageSanitized() {
      return sanitizeHtml(this.messageForView, {
        allowedTags: ['a', 'br', 'p', 'strong', 'em', 'u', 'span'],
        allowedAttributes: {
          a: ['href', 'data-id', 'style'],
          span: ['style'],
          p: ['style'],
          em: ['style'],
          u: ['style'],
          strong: ['style'],
        },
        allowedSchemesAppliedToAttributes: ['src', 'cite'],
      });
    },
    messageForView() {
      let jsonObj = this.messageJson;
      if (jsonObj) {
        if (_.isNumber(jsonObj.event)) {
          if (jsonObj.event === 0) {
            return jsonObj.question || '';
          } else if (jsonObj.event === 1) {
            return '::intent selected::';
          } else if (jsonObj.event === 2) {
            return '::location shared::';
          } else if (jsonObj.event === 3) {
            return '::form submitted::';
          } else if (jsonObj.event === 4) {
            return '::user joined chat::';
          }
        } else if (jsonObj.type) {
          if (jsonObj.type === 'text') {
            return jsonObj.content.message || '';
          } else if (jsonObj.type === 'quickreply') {
            return jsonObj.content.items[0].text;
          } else if (jsonObj.type === 'image') {
            return '::image:: ' + jsonObj.content.url;
          } else {
            return jsonObj.type;
          }
        }
      }
      return '';
    },
  },
  methods: {
    isImageFile(fileName) {
      const allowedExts = [
        'apng',
        'gif',
        'avif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
      ];
      const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
      return allowedExts.includes(ext);
    },
    loadFileUrl() {
      this.file = _defaultFile();
      const jsonObj = _.cloneDeep(this.messageJson);
      if (jsonObj) {
        if (_.isNumber(jsonObj.event)) {
          if (jsonObj.event === 5) {
            const fileUploadId = jsonObj.fileUploadId;
            this.httpclient
              .get('api/chatHistories/getFileUrl', {
                params: { fileUploadId: fileUploadId },
              })
              .then((resp) => {
                this.file = resp.data;
              });
          }
        }
      }
    },
    downloadFile(url, fileName) {
      this.isDownloading = true;
      this._downloadFileWithBlob(url, fileName).then(() => {
        this.isDownloading = false;
      });
    },
  },
};

function _defaultFile() {
  return { signedUrl: '', originalFileName: '', contentType: '' };
}
</script>

<style scoped></style>
