<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Chat History</h1>
      </b-col>
    </b-row>
    <b-form ref="field-form">
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Bot</label>
        </div>
        <div class="col-sm-2">
          <skype-bot-selector @botChanged="(bot) => (queryParams.botId = bot?.botId)"/>
        </div>
        <div class="col-sm-2">
          <label>Date Range</label>
        </div>
        <div class="col-sm-4">
          <date-range-picker
            ref="picker"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
            :minDate="minDate"
            :maxDate="maxDate"
            :timePicker="true"
            :ranges="defaultRanges()"
            v-model="dateRange"
          >
            <div slot="input" slot-scope="picker" class="form-inline">
              <small class="text-muted mr-1">from</small>
              <span class="form-control form-control-sm">{{
                picker.startDate | datetime
              }}</span>
              <small class="text-muted mx-1">to</small>
              <span class="form-control form-control-sm">{{
                picker.endDate | datetime
              }}</span>
            </div>
          </date-range-picker>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Chat ID</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.chatId"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Speaker</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.speaker"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Speaker Name</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.speakerName"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Platform</label>
        </div>
        <div class="col-sm-2">
          <b-select
            v-model="queryParams.platform"
            :options="platforms"
            size="sm"
          ></b-select>
        </div>
        <div class="col-sm-2">
          <label>Group Name</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.groupName"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Message Type</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.messageType"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Message</label>
        </div>
        <div class="col-sm-6">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.message"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Member Code</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.memberCode"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Rows Limit</label>
          <b-icon-info-circle id="pop-limit" class="clickable icon-info" />
          <b-popover
            target="pop-limit"
            placement="bottomleft"
            triggers="hover focus"
            content="This will limit the number of rows returned. Exporting report will ignore this limit."
          ></b-popover>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            type="number"
            v-model.trim="queryParams.limit"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="getData"
          >
            <b-icon-search />
            Search
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="exportData"
          >
            <b-icon-file-earmark-excel />
            Export
          </b-button>
        </div>
      </div>
    </b-form>
    <div ref="table-container">
      <small class="text-muted"
        >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
      <b-table
        bordered
        small
        :responsive="true"
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        :tbody-tr-class="rowClass"
        class="chat-histories-table"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(2)="data">
          <span class="clickable" @click="searchChatId(data.value)">
            {{ data.value }}
          </span>
        </template>
        <template v-slot:cell(0)="data"
          >{{ data.item[3] | datetimelong }}
        </template>
        <template v-slot:cell(9)="data">
          <message-viewer :message="data.value" />
        </template>
        <template v-slot:cell(11)="data">
          <small class="clickable" @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }}
          </small>
        </template>
        <template v-slot:row-details="data">
          <message-details :message="data.item[11]" />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
    </div>

    <b-modal
      size="lg"
      scrollable
      hide-footer
      ref="chat-modal"
      header-class="chat-id-header"
    >
      <template v-slot:modal-title>
        <span>Chat ID:</span>
        <input
          ref="chat-id"
          class="chat-id"
          style="cursor: pointer"
          readonly
          @click="selectChatId"
          :value="chatId"
        />
      </template>
      <chat-text :chats="chats" />
    </b-modal>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import dayjs from 'dayjs';
import _ from 'lodash';
import AppConfigs from '../../../appcode/AppConfigs';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import httpclient from '@/mixins/HttpHandler';
import MessageViewer from '@/components/ChatHistories/MessageViewer';
import MessageDetails from '@/components/ChatHistories/MessageDetails';
import ChatText from '@/components/ChatHistories/ChatText';
import SkypeBotSelector from "@/components/SkypeBots/SkypeBotSelector.vue";

export default {
  mixins: [httpclient],
  components: {
    SkypeBotSelector,
    DateRangePicker,
    MessageViewer,
    MessageDetails,
    ChatText,
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.queryParams.botId = botId;

    let datepicker = document.getElementsByClassName('reportrange-text');
    datepicker[0].classList.add('form-control-sm');

    this.dateRange = {
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().add(1, 'days').startOf('day').toDate(),
    };
  },
  data() {
    return {
      path: 'chathistories',
      queryParams: {
        fromDate: null,
        toDate: null,
        botId: null,
        chatId: null,
        speaker: null,
        speakerName: null,
        platform: null,
        groupName: null,
        message: null,
        messageType: null,
        memberCode: null,
        limit: 1000,
      },
      bots: [],
      dateRange: {},
      fields: [
        {
          key: '1',
          label: 'Bot Name',
          sortable: false,
        },
        {
          key: '2',
          label: 'Chat ID',
          sortable: true,
          tdClass: 'overflow-truncate-100',
        },
        {
          key: '0',
          label: 'Datetime',
          sortable: true,
        },
        {
          key: '4',
          label: 'Speaker',
          sortable: true,
        },
        {
          key: '5',
          label: 'Speaker Name',
          sortable: true,
        },
        {
          key: '6',
          label: 'Platform',
          sortable: true,
        },
        {
          key: '7',
          label: 'Group Name',
          sortable: true,
        },
        {
          key: '8',
          label: 'Message Type',
          sortable: true,
        },
        {
          key: '9',
          label: 'Message',
        },
        {
          key: '10',
          label: 'Member Code',
        },
        {
          key: '11',
          label: 'Content',
        },
      ],
      items: [],
      perPage: 10,
      currentPage: 1,
      url: '',
      chatId: '',
      chats: [],
    };
  },

  computed: {
    minDate() {
      return dayjs().add(-2, 'months').set('date', 1).startOf('day').toDate();
    },
    maxDate() {
      return dayjs().add(1, 'days').toDate();
    },
    rows() {
      return this.items.length;
    },
    platforms() {
      const listPlatforms = _.find(this.$store.state['appConfigs'], {
        id: AppConfigs.LIST_PLATFORMS,
      });
      if (listPlatforms) {
        return JSON.parse(listPlatforms.config);
      }
      return [];
    },
  },
  methods: {
    getData() {
      const vm = this;

      this.queryParams.fromDate = dayjs(this.dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      this.queryParams.toDate = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      vm.httpclient
        .post('api/chathistories/getlist', this.queryParams)
        .then(function (resp) {
          if (resp.data) {
            vm.items = resp.data;
            vm.currentPage = 1;

            var div = vm.$refs['table-container'];
            if (div) {
              div.scrollTop = 0;
            }
          } else {
            vm.items = [];
          }
        })
        .catch(function () {
          vm.items = [];
        });
    },
    exportData() {
      this.queryParams.fromDate = dayjs(this.dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      this.queryParams.toDate = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      this.isBusy = true;
      const vm = this;
      vm._downloadFile(
        'api/chathistories/export',
        this.queryParams,
        function () {
          vm.isBusy = false;
        }
      );
    },
    rowClass(item) {
      if (item && item[4]) {
        if (item[4].toLowerCase() === 'client') {
          return 'chat-histories__speaker-client';
        } else if (item[4].toLowerCase() === 'bot') {
          return 'chat-histories__speaker-bot';
        }
      }
      return '';
    },
    searchChatId(chatId) {
      this.chatId = chatId;

      this.httpclient
        .post('api/chathistories/getlist', { chatId: chatId, limit: 1000 })
        .then((resp) => {
          this.chats = resp.data;
          this.$refs['chat-modal'].show();
        })
        .catch(() => {
          this.chats = [];
        });
    },
    selectChatId() {
      this.$refs['chat-id'].focus();
      this.$refs['chat-id'].select();
      navigator.clipboard.writeText(this.chatId);
    },
    defaultRanges() {
      const today = dayjs().startOf('day').toDate();
      const tomorrow = dayjs().add(1, 'days').startOf('day').toDate();
      const yesterday = dayjs().add(-1, 'days').startOf('day').toDate();
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonday = dayjs().startOf('isoWeek');
      const prevMonday = dayjs().add(-7, 'days').startOf('isoWeek');

      const previousHour = dayjs().add(-1, 'hours').startOf('hour').toDate();
      const thisHour = dayjs().startOf('hour').toDate();
      const nextHour = dayjs().add(1, 'hours').startOf('hour').toDate();

      return {
        'Previous Hour': [previousHour, thisHour],
        'This Hour': [thisHour, nextHour],
        Today: [today, tomorrow],
        Yesterday: [yesterday, today],
        'Previous Week': [prevMonday, thisMonday],
        'This Week': [thisMonday, tomorrow],
        'This Month': [thisMonthStart, tomorrow],
      };
    },
  },
};
</script>
